/* || FONTS */
@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/dov/fonts/DINNextLTPro-Medium.eot');
    src: url('./assets/dov/fonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/dov/fonts/DINNextLTPro-Medium.woff2') format('woff2'),
    url('./assets/dov/fonts/DINNextLTPro-Medium.woff') format('woff'),
    url('./assets/dov/fonts/DINNextLTPro-Medium.ttf') format('truetype'),
    url('./assets/dov/fonts/DINNextLTPro-Medium.svg#DINNextLTPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
.counter {
    background-image: url("./assets/dov/R_F2_PRODUCER_SUPPLY.png");
}
@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/dov/fonts/DINNextLTPro-Bold.eot');
    src: url('./assets/dov/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/dov/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
    url('./assets/dov/fonts/DINNextLTPro-Bold.woff') format('woff'),
    url('./assets/dov/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
    url('./assets/dov/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/dov/fonts/DINNextLTPro-Heavy.eot');
    src: url('./assets/dov/fonts/DINNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./assets/dov/fonts/DINNextLTPro-Heavy.woff2') format('woff2'),
    url('./assets/dov/fonts/DINNextLTPro-Heavy.woff') format('woff'),
    url('./assets/dov/fonts/DINNextLTPro-Heavy.ttf') format('truetype'),
    url('./assets/dov/fonts/DINNextLTPro-Heavy.svg#DINNextLTPro-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* || GENERAL STYLES */
body {
    margin: 0 auto;
    background:url('./assets/dov/background.jpg');
    background-size: cover;
    font-family: 'DIN Next LT Pro', sans-serif;
}

/* || STRUCTURE */
.parent {
    min-height: calc(100vh - 86px);
    margin: 30px;
    min-width: 1197px;
}

.flex {
    display: flex;
}

/* || MENU */
.menu {
    width:183px;
    min-width:183px;
    height:100vh;
    background: url('./assets/dov/menu_bandeau_gauche.jpg') no-repeat, url('./assets/dov/menu_repeat.jpg');
    margin: 0;
}

#logo {
    position: absolute;
    height: 116px;
    width: 183px;
}

#avatar {
    background: url("https://placehold.co/103");
    background-size: cover;
    top: 157px;
    left: -2px;
    position: relative;
    width: 103px;
    height: 103px;
    text-align: center;
    margin: auto;
    border-radius: 50%;
}

.addimg {
    width: 220px;
    height: 417px;
    background-image: url("./assets/dov/new_slot_x2.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
}

#account_block {
    position: relative;
    top: 175px;
    padding: 10px 10px;
    margin: auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

#account_name {
    display: block;
}

.head {
    height:auto;
    width:100%;
    display: inline-grid;
    justify-items: end;
}

.token {
    background: url('./assets/dov/bandeau_token.jpg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    width:1067px;
    height:86px;
    display: inline-flex;
}

.checked {
    color: #ec8e33
}

.wrapper {
    display: flex;
    margin:0;
    padding:0
}

.content-wrapper {
    width:100%;
    margin:auto;
    height: 100vh;
}

.token div {
    width: 170px;
    color: #fff;
    font-size: 20px;
}

#dov-x {
    margin-left: 225px;
}

#dov-x p,#dov-f p,#dov-h p,#dov-s p,#dov-r p {
    margin: 45px 0 0 82px;
}

#dov-x p {
    color:#f1d85c
}

#menuTheater {
    position: relative;
    top: 180px;
    background: url('./assets/dov/button_theater.png');
    width: 173px;
    height: 123px;
    margin: 5px;
}

#menuWar, #menuDivision {
    position: relative;
    top: 180px;
    background: url('./assets/dov/button_war.png');
    width: 172px;
    height: 94px;
    margin: 5px;
}

#menuAssets {
    position: relative;
    top: 180px;
    background: url('./assets/dov/button_assets.png');
    width: 172px;
    height: 94px;
    margin: 5px;
}

#menuMissions {
    position: relative;
    top: 180px;
}

#menuHOF {
    position: relative;
    top: 180px;
}

#menuTheater span, #menuAssets span, #menuWar span, #menuDivision span {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 40px;
    line-height: 50px;
}

.midMenu a, .midMenuMin a, .midMenuWar a {
    font-size: 12px;
    width: 100%;
    height: 100%;
    color: #FFF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.midMenu span, .midMenuMin span, .midMenuWar span {
    font-size: 12px;
}
button {
    cursor: pointer;
}

#menuAssets:hover {
    background-image: url('./assets/dov/button_assets_active.png');
}

#menuWar:hover {
    background-image: url('./assets/dov/button_war_active.png');
}

#menuTheater:hover {
    background-image: url('./assets/dov/button_theater_active.png');
}

#menuDivision:hover {
    background-image: url('./assets/dov/button_assets_active.png');
}

.btn {
    background: url('./assets/dov/button.png');
    width: 173px;
    height: 68px;
    margin: 5px;
}

.btn:hover {
    background: url('./assets/dov/button-active.png');
}

.btn a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.midMenu {
    width: 84px;
    display: inline-block;
    text-align: center;
    height: 65px;
}

.midMenuMin, .midMenuWar {
    width: 84px;
    display: inline-block;
    text-align: center;
    height: 40px;
}

.divisions {
    background: url('./assets/dov/bandeau.jpg');
    width: 1570px;
    height: 182px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}
.avatars {
    width: 1235px;
    height: 718px;
    margin: 40px 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    color: white;
}

.avatarimg {
    width: 966px;
    height: 494px;
    margin: 40px 40px;
}

.avatarbtnimg {
    width: 195px;
    height: 62px;
    background: url('./assets/dov//avatars/GAME-avatar-bouton-save.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.divisionsADD {
    background: url('./assets/dov/bandeau_add.jpg');
    width: 1570px;
    height: 182px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.divisionsDOV {
    background: url('./assets/dov/bandeau_dov.jpg');
    width: 1570px;
    height: 182px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.divisions:hover {
    background: url('./assets/dov/bandeau_actif.jpg');
    width: 1570px;
    height: 182px;
    background-size: contain;
    background-repeat: no-repeat;
}

.divImage {
    width: 185px;
    height: 100%;
    float: left;
}

.divAvatar {
    background: url("https://placehold.co/103");
    background-size: cover;
    top: 39px;
    position: relative;
    width: 103px;
    height: 103px;
    text-align: center;
    margin: auto;
    border-radius: 50%;
}

.divTitle {
    width: 560px;
    height: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    font-size: 50px;
    padding-left: 20px;
    color: #fff;
    float: left;
}

.divColumn {
    float: left;
    height: 100%;
    width: 265px;
}

.stats {
    width: 230px;
    height:100%;
    color: #fff;
    text-align: right;
}

#table_division1 .stats_divisions {
    width: 280px !important;
}
.stats_divisions {
    width: 273px;
    margin-left: 6px;
}

.stats_divisions th {
    height: 40px;
    text-transform: uppercase;
    color: #15344a;
    font-size: 20px;
}
.stats_divisions td {
    color: #fff;
    line-height: 23px;
    text-align: right;
}
.stats_divisions td:first-child {
    width: 180px;
}
.statsLarge {
    width: 270px;
    height:100%;
    color: #fff;
    text-align: right;
}

.stats th {
    text-transform: uppercase;
    font-size: 17px;
}

.stats td {
    padding-right: 10px;
    font-size:15px;
}

.statsLarge td {
    padding-right: 10px;
    font-size:15px;
}

/* CREATION DE DIVISION */

#bandeau_haut {
    background: url('./assets/dov/bandeau_haut.jpg');
    background-size: cover;
    width: 1632px;
    min-height: 90px;
    display: grid;
    margin: 0 0 10px 25px;
}

#board_division {
    background: url('./assets/dov/Div-bloc1.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 723px;
    height: 300px;
    float: left;
    margin-left: 9px;
}
#table_division1 {
    background: url('./assets/dov/GAME_Zone-2.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 296px;
    height: 312px;
    float: left;
    margin-left:10px
}

#table_division2 {
    background: url('./assets/dov/GAME_Zone-3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 288px;
    height: 312px;
    float: left;
}

#table_division3 {
    background: url('./assets/dov/GAME_Zone-4.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 288px;
    height: 312px;
    float: left;
}

#board_division2 {
    background: url('./assets/dov/GAME_Zone-5.png');
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    clear: both;
    width: 724px;
    height: 467px;
    margin-left: 9px;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#stats_division {
    background: url('./assets/dov/Div-bloc3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    width: 346px;
    height: 498px;
    margin-left: 10px;

}

#artillery_division {
    background: url('./assets/dov/Div-bloc4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    width: 518px;
    height: 499px;
    margin-left: 8px;
}

.creaDivTitle {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 36px;
    color: #fff;
    margin: 4px 0 0 130px;
}

/* || CARDS DIVISION */
#cards_hq_title {
    width: 55px;
    float: left;
    height: 100%;
    color: #14344a;
    font-size: 16px;
    font-weight: 600;
}

#cards_front_title {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

.rotate {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    float: left;
    position: relative;

}

#one {
    width: 110px;
    left: -40px;
    top: 123px;
}

#second {
    width: 120px;
    left: -40px;
    top: 83px;
}

#third {
    width: 120px;
    left: -40px;
    top: 256px;
}

.cards_hq {
    width: 130px;
    height: 100px;
    float: left;
    text-align: center;
    margin: 65px 35px 0 0;
}
.cards_hq img {
    width: 100px;
    height: 189px;
    cursor: pointer;
}
#cards_front_title {
    width: 50px;
    float: left;
    height: 100%;
}
.cards_front {
    width: 130px;
    float: left;
    text-align: center;
    margin: 35px 2px 0 0;
}
.cards_front img {
    width: 100px;
    height: 190px;
    cursor: pointer;
}

/* SELECT A CARD */
#select_card {
    height: 100%;
    overflow: scroll;
    scrollbar-color: #1b3d53 transparent;
    scrollbar-width: thin;
}

#select_card_title {
    width: 100%;
    color: #2a516b;
    height: 25px;
    text-align: center;
    padding-top: 5px;
    text-transform: uppercase;
    font-size: 18px;
}

.sCards {
    float: left;
    text-align: center;
    width: 25%;
    height: 130px;
    background: #bdbdbd;
    border: 1px solid #ffffff33;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.sCards:hover {
    background: #fff;
}

.sCards img {
    width: 42px;
    margin-top: 10px;
    cursor: pointer;
}

.sCards p {
    text-transform: uppercase;
    font-size: 9px;
    text-align: center;
    margin: 0px 5px;
    color: #426278;
    font-weight: bold;
}
/* || CARDS DETAILS */
#selectedCard {
    width: 100%;
    height: 115px;
    display: flex;
}
#selectedCard img {
    width: 55px;
    height: 104px;
    margin: 7px 20px 0 28px;
}
#selectedCard span {
    align-self: center;
    color: #fff;
    font-size: 21px;
    text-transform: uppercase;
    line-height: 20px;
}
#selectedTable {
    width: 300px;
    margin: 5px 0 0 10px;
    float:left;
    mso-cellspacing: 0;
    border: 0px;
}
#selectedTable table td {
    font-size: 15px;
}
#selectedTable table td:first-child {
    width: 233px;
}
#selectedTerrain {
    float: left;
    width: 90px;
    margin-left: 7px;
}
#selectedBonus {
    float: left;
    width: 90px;
    margin-left: 12px;
}

/* || COLORS */
.red {color: #fe1010;}
.orange {color: #fe9502;}
.green {color: #16b501;}

/* || EXCHANGE */
#exchange {
    width: 1461px;
    background-image: url("./assets/dov/exchange/GAME_Exchange-Withdraw_only.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}
#dexchange {
    width: 1461px;
    background-image: url("./assets/dov/exchange/GAME_Exchange-Deposit_only.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}
.toggleWithdraw, .toggleDeposit {
    width: 50%;
    height: 62px;
    float: left;
    cursor:pointer;
}
#contentWithdraw, #contentDeposit {
    width: 100%;
    height: 459px;
    float: left;
}
#btnWithdraw, #btnDeposit {
    float: right;
    width: 214px;
    height: 80px;
}
.feeContent {
    float: left;
    width: 378px;
    height: 100%;
}
.tbContent {
    float: left;
    width: 189px;
    height: 100%;
    margin-right: 28px;
}
.mgfee {
    width: 326px;
    height: 402px;
    position: relative;
    top: 29px;
    left: 27px;
}
.mgfee h2 {
    margin: 0px 35px 10px 35px;
    font-size: 30px;
    color: #fdfb54;
}
.mgfee h2 span {
    color: #fff;
}
.mgfee p {
    margin: 0px 35px;
    font-size: 19px;
    color: #fdfb54;
}
#textFee {
    height: 155px;
    margin-top: 35px;
}
.mgfee table {
    width: 256px;
    margin: 0px 35px;
}
#firstCell {
    height: 46px !important;
    font-size: 28px;
}
#srCell {
    height: 46px !important;
    font-size: 23px;
    top:15px;
    position: relative;
}

#trCell {
    height: 46px !important;
    font-size: 28px;
    top:22px;
    position: relative;
}
.mgfee table td {
    font-size: 30px;
    color: #fff;
    text-align: right;
    height: 70px;
}
.tbContent table {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
    margin-top: 218px;
}
.tbContent td {
    height: 70px;
}
/* || BUILDINGS */
#buildings {
    width: 1459px;
    height: 515px;
    background-image: url("./assets/dov/buildings/GAME_Buildings-fond-slots.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
}
.firstCard, #firstButton {
    margin-left: 50px;
}
.cards_buildings img {
    width: 230px;
    margin-bottom: 15px;
}
.cards_buildings {
        float: left;
    width: 315px;
    text-align: center;
    margin-top: 62px;
    height: 500px;
    position: relative;
    z-index: 1;
    margin-right: 20px;
}
.cards_button {
    float: left;
    width: 315px;
    height: 153px;
    text-align: center;
    position: relative;
    z-index: 0;
    margin: 25px 20px 20px 0;
    background-image: url("./assets/dov/buildings/GAME_Buildings-bouton-collect.png");
    background-repeat: no-repeat;
    background-size:cover;
}
.cards_buttond {
    float: left;
    width: 25px;
    height: 95px;
    text-align: center;
    position: relative;
    top: 0px;
    background-image: url("./assets/dov/buildings/GAME_Buildings-dovx-four.png");
}
.collect_buttonz {
    float: left;
    width: 25px;
    height: 95px;
    text-align: center;
    position: relative;
    top: 0px;
    background-image: url("./assets/dov/buildings/GAME_Buildings-bouton-collect.png");
    background-repeat: no-repeat;
    background-size:cover;
    z-index: 0;
}
.dovxZone {
    float: left;
    position: relative;
    z-index: 0;
    margin-right: 20px;
    background-image: url('./assets/dov/buildings/GAME_Buildings-dovx-four.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    width: 315px;
    height: 152px;
}
.dovxRedZone {
    float: left;
    position: relative;
    z-index: 0;
    margin-right: 20px;
    background-image: url('./assets/dov/craft/GAME_craft-bloc-rouge-B.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    width: 315px;
    height: 152px;
}
.dovxText {
    width: 50%;
    float: left;
    height: 76px;
}
.dovxText h3 {
    font-size: 22px;
    color: #303030;
    line-height: 0.5;
    margin: 15px 0 0 0;
    font-weight: 500;
}
.dovxText p {
    color: red;
    font-size: 20px;
    line-height: 1;
    margin: 10px 0 5px 0;
    font-weight: 600;
}
.dovxText h4 {
    font-size: 20px;
    line-height: 1;
    margin: 10px 0 5px 0;
    font-weight: 600;
    color: green;
}
.selected:hover {
    background-image: url('./assets/dov/buildings/GAME_Buildings-cadre-carte-vert.png');
    background-size: 240px;
    background-repeat: no-repeat;
    background-position: top center;
}
.selected:hover ~ .characteristic
{
    display: block;
}
.characteristic {
    background-image: url('./assets/dov/buildings/GAME_Buildings-caracteristiques.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1113px;
    height: 283px;
    position: absolute;
    top: 650px;
    left: 26%;
    z-index: 3;
    display: none;
}
.charContent {
    width: 50%;
    float: left;
    height: 100%;
}
.charContent p {
    padding: 15px 50px;
    color: #fff;
    font-size: 22px;
}
.charContent table {
    width: 95%;
    color: #fff;
    font-size: 26px;
    margin-top: 15px;
    text-align: right;
}
.charContent table tr {
    height: 48px;
}
.charContent table tr td:first-child {
    width: 370px;
}
.select_card_popup {
    background-image: url('./assets/dov/buildings/GAME_Buildings-bloc-select-card.png');
    width: 743px;
    height: 568px;
    overflow: auto;
    overflow-x: hidden;
    position: fixed;
    top: 20%;
    left: 35%;
    z-index: 4;
}
.select_card_content {
    width: 100%;
    margin: 31px 0 0 10px;
    scrollbar-color: #1b3d53 transparent;
    scrollbar-width: thin;
    overflow-x: hidden;
    height: auto;
    min-height: 495px;
}
.sCardsB {
    float: left;
    text-align: center;
    width: 241px;
    height: 390px;
}
.sCardsB:hover {
    background: #fff;
}
.sCardsB img {
    width: 160px;
    margin-top: 20px;
}
.startCounter {
    background-image: url('./assets/dov/buildings/GAME_Buildings-cadre-chrono-gris.png');
    background-size: cover;
    width: 315px;
    height: 680px;
    margin-left: 26px;
}

.startRedCounter {
    background-image: url('./assets/dov/craft/GAME_craft-bloc-rouge-A.png');
    background-size: cover;
    width: 315px;
    height: 680px;
    margin-left: 26px;
}

.endCounter {
    background-image: url('./assets/dov/buildings/GAME_Buildings-cadre-chrono-vert.png');
    background-size: cover;
    width: 315px;
    height: 680px;
    margin-left: 26px;
}
.textRedCounter {
    margin-top: 40px;
}
.textCounter {
    margin-top: 25px;
}
.textCounter h2, .textRedCounter h2 {
    font-size: 25px;
    font-weight: 500;
    color: #303030;
    line-height: 1.4;
}
.textCounter h3 {
    font-size: 25px;
    font-style: italic;
    color: #dae675;
    line-height: 1.4;
    margin: 38px 0 0 0;
}
.timerz {
    color: #fff;
    font-size: 26px;
    line-height: 1;
    margin: 0;
    font-weight: 500;
}
.textRedCounter h3 {
    font-size: 28px;
    font-style: italic;
    color: #ff929e;
    line-height: 1;
    margin: 0;
}
.textCounter p {
    color: #fff;
    font-size: 26px;
    line-height: 1;
    margin: 10px;
    font-weight: 500;
}
.textRedCounter p {
    color: #fff;
    font-size: 32px;
    line-height: 0.7;
    margin: 10px;
    font-weight: 500;
}
.collect_button {
    float: left;
    width: 315px;
    text-align: center;
    position: relative;
    top: 0px;
    z-index: 0;
}
.collect_button img {
    width: 315px;
    height: 151px;
    margin-top: 20px;
}

.staking-assets-filters-button {
    width: 32px;
    height: 32px;
    border: 0px;
    float: right;
    background: #bc4552;
    color: #fff;
    font-weight: bold;
}

.staking-assets-filters {
    padding: 8px 15px;
    background: #e3e3e3;
}

select.optionSelect {
    margin: 0 10px;
    height: 25px;
    min-width: 100px;
    border: 0px;
}

.staking-asset-name-wrapper {
    padding-top: 10px;
}

.disassemblyBtn {
    background-image: url(".../../assets/dov/GAME_Buildings-bouton-DISASSEMBLY.png");
    width: 121px;
    height: 37px;
    border: 0px;
    margin-top: 15px;
}

.removeBtn {
    background-image: url(".../../assets/dov/removeBtn.png");
    width: 23px;
    height: 23px;
    border: 0px;
    position: absolute;
    background-size: cover;
    background-color: transparent;
    cursor: pointer;
    transition: 0.50s;
    margin-left: -15px;
}

.removeBtn:hover {
    transform: rotate(3.142rad);
    transition: 0.50s;
}

.craftBtn {
    background-image: url(".../../assets/dov/craft/GAME_craft-bouton-CRAFT.png");
    width: 121px;
    height: 37px;
    border: 0px;
    margin-top: 28px;
}
.floatText {
    position: absolute;
    bottom: 230px;
    left: 38%;
}
.redColor {
    color: red !important
}
.greenColor {
    color: green !important
}
.blueColor {
    color: blue !important
}
.whiteColor {
    color: white !important
}
.miniText {
    font-size: 13px !important;
    margin: 0px !important;
}
/* DIVISION V2 */
.leftZone {
    background: #80afcf;
    width: 259px;
    height: 960px;
    float: left;
    margin-left: 10px;
}
.rightZone {
    background: #80afcf;
    width: 1400px;
    height: 960px;
    float: left;
    margin-left: 42px;
}
.divSelector {
    width: 770px;
    height: 100%;
    float: left;
}
.divCards{
    width: 630px;
    height: 100%;
    float: left;
}
.titleSelector {
    background: #4c80a2;
    height: 35px;
    margin: 15px 35px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-weight: 500;
    position:relative;
}
.titleSelectorL {
    background: #4c80a2;
    height: 35px;
    margin: 15px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-weight: 500;
    position:relative;
}
.titleSelector span {
    border-top: 10px solid #4c80a2;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 44%;
}
.titleSelectorL span {
    border-top: 10px solid #4c80a2;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 36%;
}
.arrowHR {
    border-top: 17px solid #4c80a2;
    border-right: 50px solid transparent;
    border-left: 50px solid transparent;
    width: 0px;
    text-align: center;
    margin: auto;
}
.divBtn {
    height: 35px;
    margin: 15px 35px;
}
.divBtn button {
    background: #e68833;
    width: 233px;
    border: 1px solid #b3491d;
    margin-left: 0px;
    height: 30px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.divBtn button:hover {
    background: #fff;
    color: #e68833;
}
.openZone {
    background: #235a80;
    margin: 3px 15px 3px 15px;
    height: 111px;
    padding: 5px;
}
.imgZone {
    float: left;
    width: 88px;
    height: auto;
    margin-right: 5px;
}
.imgZone img {
    width: 88px;
}
.btnZone {
    float: left;
    width: 126px;
}
.btnZone button {
    width: 100%;
    background: #235a80;
    color: #133f5e;
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    border: 1px solid #1a3a4f;
}
.selected {
    background: #e68833 !important;
    color: #fff !important;
    border: 1px solid #b3491d !important;
}
.costZone {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__COST.png");
    background-size: cover;
    width: 228px;
    height: 88px;
    margin: 5px 15px 5px 15px;
}
.costZone span {
    text-align: end;
    color: white;
    width: 98%;
    display: flex;
    flex-direction: column;
    padding-top: 17px;
    font-size: 18px;
    line-height: 15px;
}
.winsZone {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__WINS.png");
    background-size: cover;
    width: 228px;
    height: 36px;
    margin: 0px 15px;
}
.winsZone span {
    font-size: 30px;
    color: #fff;
    display: block;
    width: 68px;
    text-align: center;
    padding-top: 6px;
}
.earningsZone {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__EARNINGS.png");
    background-size: cover;
    width: 228px;
    height: 33px;
    margin: 5px 15px;
}
.earningsZone span {
    text-align: right;
    display: block;
    font-size: 18px;
    color: #fff;
    padding-top: 10px;
    margin-right: 9px;
}
.divZone {
    height: 35px;
    background: #fff;
    margin: 0 35px 5px 35px;
    border: 1px solid #8f8c8c;
}

.mgt15 {
    margin-top: 15px;
}

.mgr15 {
    margin-right: 15px;
}

.divLeftDP {
    width: 65%;
    height: 100%;
    float: left;
    line-height: 35px;
    font-size: 19px;
    font-weight: 600;
}
.divLeftDP span {
    margin-left: 57px;
}
.divRightDP {
    width: 35%;
    height: 100%;
    float: left;
    text-align: right;
    background: #dedede;
    color: #fff;
    border: 5px #fff solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    line-height: 28px;
    font-size: 19px;
}
.orangeColor {
    background: #e68834 !important;
    color: #fff !important;
}
.greenBack {
    background: #00a186 !important;
    color: #fff !important;
}
.whiteColor {
    color: white !important
}
.redBackColor {
    background: rgb(84, 4, 4) !important;
    color: #fff !important;
}
.blackColor {
    background: #303030 !important;
    color: #fff !important;
}
.divRightDP span {
    margin-right: 15px;
}
.divAuth {
    width: 50%;
    float: left;
}
/*.divAuth button:hover {
    background: #e68834;
    color: #fff;
}*/
.divVDD {
    width: 33%;
    float: left;
}
.divAuth button, .divVDD button {
    width: 100%;
    height: 100%;
    padding: 5px;
    background: #fff;
    border: 5px solid #fff;
    color: #dedede;
    font-size: 18px;
    font-weight: 600;
    line-height: 13px;
}
#selectCard select {
    float: right;
    position: absolute;
    right: 0;
    height: 100%;
    background: #2a516b;
    border: 5px solid #4c80a2;
    color: #3475a0;
    min-width: 125px;
    font-size: 15px;
    font-weight: 600;
}
.blocCards {
    height: 600px;
    background: #6898b9;
    margin: 0 35px;
}
.btnScroll {
    margin: 0px;
    background: none;
}
.btnScroll button {
    border: none;
    margin: 0;
    padding: 0;
}
.btnSave {
    margin: 0 35px;
    float: right;

}
.btnReset {
    float:left;
    margin-left: 20px;
}
.btnFight {
    width: 100%;
    height: 100%;
    display: flex;
}
.btnSave button {
    background: #e68833;
    color: #fff;
    border: none;
    height: 35px;
    min-width: 150px;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    box-shadow: 4px 4px #457790;
    transition: 0.25s;
}
.btnReset button {
    background: #540404;
    color: #fff;
    border: none;
    height: 35px;
    min-width: 150px;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    box-shadow: 4px 4px #457790;
    transition: 0.25s;
}
.btnFight button {
    background: #e95a24;
    color: #fff;
    border: none;
    height: 35px;
    min-width: 150px;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 4px 4px #944700;
    transition: 0.25s;
    margin: auto;
    letter-spacing: 1px;
    height: 60px;
}
.btnSave button:hover, .btnFight button:hover, .btnBlue button:hover, .btnReset button:hover {
    transform: translateY(-0.10em);
    transition: 0.25s;
}

/* FIGHT */
.divFightZone {
    background-image: url("../src/assets/dov/fight/fightZone.png");
    background-size: cover;
    width: 100%;
    height: 140px;
    max-width: 1500px;
    background-repeat: no-repeat;
    text-align: center;
    margin: 30px auto;
}
.divFightZone span {
    font-size: 45px;
    color: #81b0d0;
    font-weight: 100;
    line-height: 150px;
}
.divFightZoneActive {
    background-image: url("../src/assets/dov/fight/fightZoneActive.png");
    background-size: contain;
    width: 100%;
    height: 140px;
    max-width: 1500px;
    background-repeat: no-repeat;
    text-align: center;
    margin: 30px auto;
}
.divFightTitle span {
    font-size: 45px;
    color: #fff;
    font-weight: 100;
    line-height: 150px;
}
.divFightTitle {
    float: left;
    width: 21%;
    height: 100%;
}
.divFightInfo {
    float: left;
    width: 16%;
    height: 100%;
}
.divFightInfo2 {
    float: left;
    width: 34%;
    height: 100%;
}
.divFightVictory {
    float: left;
    width: 13%;
    height: 100%;
}
.divFightButton {
    float: left;
    width: 16%;
    height: 100%;
}
.costZoneMin {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__COST.png");
    background-size: cover;
    width: 180px;
    height: 69px;
    margin: 2px 15px 5px 15px;
    display: flex;
    flex-direction: column;
}
.costZoneMin span {
    text-align: end;
    color: white;
    width: 100%;
    display: block;
    height: 20px;
    line-height: 1;
    font-size: 12px;
    padding-top: 25px;
    padding-right: 8px;
}
.winsZoneMin {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__WINS.png");
    background-size: cover;
    width: 180px;
    height: 28px;
    margin: 0px 15px;
}
.winsZoneMin span {
    font-size: 25px;
    color: #fff;
    display: block;
    width: 48px;
    text-align: center;
    padding-top: 17px;
    height: 24px;
    line-height: 0;
}
.earningsZoneMin {
    background: url("../src/assets/dov/divisions/DIV_CREA-Zone__EARNINGS.png");
    background-size: cover;
    width: 180px;
    height: 26px;
    margin: 5px 15px;
}
.earningsZoneMin span {
    text-align: right;
    display: block;
    font-size: 12px;
    color: #fff;
    padding-top: 15px;
    margin-right: 8px;
    height: 17px;
    line-height: 0;
}
.verticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mgt10 {
    margin-top: 10px !important;
}
.divZoneMin {
    height: 32px;
    background: #fff;
    margin: 0 50px 5px 35px;
    border: 1px solid #8f8c8c;
}

.divLeftDPMin {
    width: 65%;
    height: 100%;
    float: left;
    line-height: 35px;
    font-size: 15px !important;
    font-weight: 600;
}
.divLeftDPMin span {
    margin-left: 43px;
    text-align: left;
    display: block;
}
.divRightDPMin {
    width: 35%;
    height: 100%;
    float: left;
    text-align: right;
    background: #dedede;
    color: #fff;
    border: 5px #fff solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    line-height: 25px;
    font-size: 15px;
}
.divRightDPMin span {
    margin-right: 15px;
}
.divAuthMin {
    width: 50%;
    float: left;
}
/*.divAuthMin button:hover {
    background: #e78933;
    color: #fff;
}*/
.divVDDMin {
    width: 33%;
    float: left;
}
.divAuthMin button, .divVDDMin button {
    width: 100%;
    height: 100%;
    padding: 5px;
    background: #fff;
    border: 5px solid #fff;
    color: #dedede;
    font-size: 15px;
    font-weight: 600;
    line-height: 10px;
}
.divFightVictory span {
    display: block;
}
span.orangeBlock {
    background: #e78933;
    display: inline-block;
    padding: 8px 10px;
    font-size: 20px;
    color: #14344a;
}
span.whiteBlock {
    padding: 5px 10px;
    font-size: 20px;
    color: #14344a;
}
/* AVATAR */
[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.avatar {
    text-align: center;
    margin: auto;
}
.avatar label {
    margin: 30px;
}
[type="radio"] + img {
    cursor: pointer;
    border-radius: 50%;
    width: 160px;
}

[type="radio"]:hover + img {
  outline: 10px solid #fff;
}

[type="radio"]:checked + img {
    outline: 10px solid rgb(0, 251, 100);
    transform: scale(1.1);
    transition: 0.50s;
}
.avatar h1 {
    color: #fff;
}
.avatar h2 {
    color: #fff;
    margin-bottom: 50px
}
.btnBlue button {
    margin-top: 30px;
    color: #fff;
    border: none;
    height: 65px;
    width: 180px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    background: linear-gradient(90deg, rgba(23,62,89,1) 0%, rgba(35,80,108,1) 100%);
    box-shadow: 4px 4px #5d84a0;
}

/* Hall Of Fame */
.left50 {
    width: 50%;
    float: left;
}
.right50 {
    width: 50%;
    float: left;
}
#topOfficer {
    background: #215378;
    height: 60px;
}
#topOfficer:after{
    content:url("./assets/dov/hof/arrow_hof.png");
    /* content:url("../../assets/dov/hof/arrow_hof.png"); */
    position:relative;
    left: 65%;
    bottom: 100%;
}
#topArmed {
    background: #2b6b99;
    height: 60px;
    margin-top: 35px;
    width: 90%;
}
#topArmed .arrow {
    float: left;
    width: 12%;
}
#topArmed .arrow:after{
    content:url("./assets/dov/hof/arrow_hof.png");
    /* content:url("../../assets/dov/hof/arrow_hof.png"); */
    position:relative;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-flex;
}
#topOfficer .topTitle, #topArmed .topTitle {
    float: left;
    width: 25%;
}
.topTitle span {
    font-size: 40px;
    font-weight: 600;
    color: #bf9356;
    line-height: 65px;
    margin: 20px;
}
#topOfficer .topDesc {
    float: left;
    width: 75%;
}
#topArmed .topDesc {
    float: left;
    width: 63%;
}
.topDesc h2 {
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 0;
    padding: 10px 0 0 0;
}
.topDesc span {
    color: #fff;
    line-height: 5px;
    font-size: 15px;
}
#ArmedTable {
    width: 90%;
}
.tableHoF table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}
.tableHoF table, .tableHoF th, .tableHoF td {
    border: 1px solid #1e445c !important;
    color: #fff;
    font-weight: 600 !important;
    line-height: initial !important;
}
.tableHoF table:after {
    content: "";
    background-image: url("./assets/dov/hof/texture.png");
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}
.tableHoF table tr td:first-child {
    background: #81633a;
    text-align: center;
    max-width: 15px;
}
.tableHoF table tr td:nth-child(2) span {
    margin-left: 20px;
}
.tableHoF table tr td:first-child span {
    font-size: 23px;
    color: #e8cf5c;
    text-shadow: 2px 2px #40260d;
    font-weight: 800;
}
.tableHoF table tr td:last-child {
    background: #4487b0;
    text-align: center;
    width: 30%;
}
.tableHoF table tr td {
    background: #186585;
}
.leftZoneHoF {
    background: #205478;
    float: left;
    max-width: 15%;
}
.leftZoneHoF img {
    margin: 5px;
    height: 142px;
}
.rightZoneHoF {
    background: lightblue;
    float: left;
    width: 85%;
}
.btnWithdraw{
    width: 176px;
    height: 57px;
    position: relative;
    background-image: url("./assets/dov/exchange/btn_withdraw.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
    cursor: pointer;
    float: right;
}
.btnDD{
    width: 176px;
    height: 57px;
    position: relative;
    background-image: url("./assets/dov/exchange/btn_deposit.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
    cursor: pointer;
    float: right;
}
.mr0 {
    margin-right: 0px !important;
}
.header-wallet {
    line-height: 0;
}

/* Tools */
.tools-asset-name {
    padding: 20px 0 5px 0;
}
.tools-asset-name h2 {
    font-size: 20px;
}
.tools-asset-rate {
    padding: 8px 0;
}
.tools-asset-rate h3 {
    font-size: 25px;
    font-weight: 500;
    color: #303030;
    line-height: 1.4;
}

@media (max-width: 1920px) {
    .removeBtn {
        width: 18px;
        height: 18px;
    }
    /* CREATE DIVISION */
    .leftZone {
        margin-left: 45px;
    }
    .rightZone {
        margin-left: 30px;
        width: 1280px;
    }
    .divSelector {
        width: 700px;
    }
    .divCards {
        width: 580px;
    }
    .divBtn button {
        width: 210px;
    }
    #board_division {
        width: 656px;
        height: 272px;
    }
    #board_division2 {
        width: 656px;
        height: 423px;
    }
    #one {
        top: 107px;
        font-size: 14px;
    }
    #cards_front_title {
        font-size: 14px;
    }
    #second {
        top: 70px;
        left: -45px;
    }
    #third {
        left: -45px;
        top: 232px;
    }
    .cards_hq {
        width: 110px;
        margin: 65px 40px 0 0;
    }
    .cards_hq img {
        width: 80px;
        height: auto;
    }
    .cards_front {
        width: 118px;
        margin: 35px 0 0 0;
    }
    .cards_front img {
        width: 88px;
        height: 166px;
    }
    /* END CREATE DIVISION */
}

@media (max-width: 1872px) {
    /* CREATE DIVISION */
    .leftZone {
        margin-left: 20px;
        height: 860px;
    }
    .rightZone {
        margin-left: 30px;
        width: 1200px;
        height: 860px;
    }
    .divSelector {
        width: 660px;
    }
    .divCards {
        width: 540px;
    }
    .divBtn button {
        width: 196px;
    }
    #board_division {
        width: 616px;
        height: 255px;
    }
    #board_division2 {
        width: 616px;
        height: 397px;
    }
    #one {
        top: 95px;
        font-size: 13px;
        left: -44px;
    }
    #cards_front_title {
        font-size: 13px;
    }

    #second {
        top: 62px;
        left: -48px;
    }
    #third {
        left: -48px;
        top: 215px;
    }
    #cards_hq_title {
        width: 46px;
    }
    .cards_hq {
        width: 111px;
        margin: 60px 31px 0 0;
    }
    .cards_hq img {
        width: 80px;
        height: 130px;
    }
    .cards_front {
        width: 111px;
        margin: 35px 0 0 0;
    }
    .cards_front img {
        width: 80px;
        height:151px;
    }
    /* END CREATE DIVISION */
}

@media (max-width: 1792px) {
    .leftZoneHoF {
        max-width: 18%;
    }
    .rightZoneHoF {
        width: 80%;
    }
    .topTitle span {
        font-size: 32px;
    }
    .divFightTitle span {
        line-height: 135px;
    }
    .divFightButton {
        height: 92%;
    }
    .btnFight button {
        font-size: 22px;
        height: 50px;
    }
    .costZoneMin {
        width: 160px;
        height: 62px;
    }
    .costZoneMin span {
        padding-top: 23px;
    }
    .winsZoneMin {
        width: 160px;
        height: 24px;
    }
    .winsZoneMin span {
        font-size: 20px;
        padding-top: 14px;

    }
    .earningsZoneMin {
        width: 160px;
        height: 23px;
    }
    .earningsZoneMin span {
        padding-top: 13px;
    }
    /* CREATE DIVISION */
    .leftZone {
        margin-left: 20px;
    }
    .rightZone {
        margin-left: 30px;
        width: 900px;
    }
    .divSelector {
        width: 495px;
    }
    .divCards {
        width: 405px;
    }
    .divBtn button {
        width: 141px;
    }
    #board_division {
        width: 449px;
        height: 186px;
    }
    #board_division2 {
        width: 449px;
        height: 289px;
    }
    #one {
        top: 56px;
        font-size: 10px;
        left: -44px;
    }
    #cards_front_title {
        font-size: 10px;
        width: 35px;
    }
    #second {
        top: 30px;
        left: -48px;
    }
    #third {
        left: -48px;
        top: 139px;
    }
    #cards_hq_title {
        width: 34px;
    }
    .cards_hq {
        width: 81px;
        margin: 42px 22px 0 0;
    }
    .cards_hq img {
        width: 60px;
        height: 110px;
    }
    .cards_front {
        width: 80px;
        margin: 26px 0 0 0;
    }
    .cards_front img {
        width: 60px;
        height: 113px;
    }
    .blocCards {
        height: 400px;
    }
    .divLeftDP {
        font-size: 14px;
    }
    .divAuth button, .divVDD button, .titleSelector {
        font-size: 14px;
    }
    #selectCard select {
        min-width: 85px;
        font-size: 14px;
    }
    /* END CREATE DIVISION */
    .token {
        height: 70px;
    }
    .addimg {
        width: 180px;
        height: 342px;
    }
    #dov-x {
        margin-left: 367px;
    }
    #dov-x p, #dov-f p, #dov-h p, #dov-s p, #dov-r p {
        margin: 37px 0 0 67px;
    }
    #bandeau_haut {
        width: 1540px;
    }
    #table_division1 {
        width: 277px;
        height: 292px;
    }
    #table_division2, #table_division3 {
        width: 270px;
        height: 292px;
    }
    #stats_division {
        width: 330px;
        height: 476px;
    }
    #artillery_division {
        width: 479px;
        height: 460px;
    }
    /* || DIVISIONS */
    .divisions, .divisions:hover, .divisionsADD, .divisionsDOV {
        width: 1300px;
        height: 148px;
    }
    .divImage {
        width: 152px;
    }
    .divAvatar {
        top: 31px;
        width: 86px;
        height: 86px;
    }
    .divTitle {
        width: 440px;
        font-size: 35px;
    }
    .divColumn, .stats {
        width: 215px;
    }
    .statsLarge {
        width: 248px;
    }
    .stats td,.statsLarge td {
        font-size:13px
    }
    .stats th {
        font-size: 15px;
        padding-right: 15px;
    }
    /* || TABLEAU CREATE DIVISION */
    .stats_divisions th {
        height: 40px;
        font-size: 19px;
    }
    #table_division1 .stats_divisions {
        width: 260px !important;
    }
    .stats_divisions {
        width: 255px;
    }
    .stats_divisions td {
        font-size: 15px;
        line-height: 21px;
    }
    .stats_divisions td:first-child {
        width: 170px;
    }
    /* || SELECT A CARD */

    #select_card_title {
        height: 25px;
    }

    .sCards {
        width: 102px;
        height: 120px;
    }

    .sCards img {
        width: 40px;
    }

    .sCards p {
        margin: 0px 5px;
    }
    /* || CARDS DETAILS */
    #selectedCard {
        height: 107px;
    }
    #selectedCard img {
        width: 50px;
        height:95px;
    }
    #selectedCard span {
        font-size: 18px;
        line-height: 17px;
    }
    #selectedTable {
        width: 275px;
    }
    #selectedTable table td {
        font-size: 14px;
    }
    #selectedTable table td:first-child {
        width: 215px;
    }
    #selectedTerrain, #selectedBonus {
        width: 85px;
    }
    /* || EXCHANGE */
    #exchange {
        width: 1200px;
    }
    #dexchange {
        width: 1200px;
    }
    .toggleWithdraw, .toggleDeposit {
        height: 50px;
    }
    #contentWithdraw, #contentDeposit {
        height: 378px;
    }
    #btnWithdraw, #btnDeposit {
        width: 176px;
        height: 66px;
    }
    .feeContent {
        float: left;
        width: 310px;
        height: 100%;
    }
    .tbContent {
        float: left;
        width: 155px;
        height: 100%;
        margin-right: 24px;
    }
    .mgfee {
        width: 267px;
        height: 330px;
        top: 25px;
        left: 22px;
    }
    .disassemblyBtn img, .craftBtn img {
        width: 105px;
        height: 35px;
        margin-top: 13px;
    }
    .dovxZone {
        float: left;
        position: relative;
        z-index: 0;
        margin-right: 20px;
        display: grid;
        grid-template-columns: auto auto;
        background-image: url('.../../assets/dov/buildings/GAME_Buildings-dovx-four.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        width: 121px;
        height: 121px;
    }
    .dovxCZone {
        float: left;
        position: relative;
        top: 20px;
        z-index: 0;
        width: 238px;
        top: 27px;
        margin-right: 20px;
        display: grid;
        grid-template-columns: auto auto;
        background-image: url('.../../assets/dov/craft/GAME_craft-bloc-rouge-B.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        width: 121px;
        height: 121px;
    }
    .dovxText {
        height: 27px;
        font-size: 18px;
        font-weight: 500;
        color: #303030;
        margin: 3px 0 0 0;
    }
    .dovxText h3 {
        font-size: 21px;
    }
    .dovxText h2 {
        font-size: 21px;
    }
    .dovxText h4 {
        font-size: 14px;
        color: green;
    }
    .dovxText p {
        color: red;
        font-size: 14px;
    }
    .selected:hover {
        background-size: 190px;
    }
    .option-image-2
    {
        background-image: url('.../../assets/icons/anchor-icon.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 50px;
        height: 50px;
    }
    .option-image-1
    {
        background-image: url('.../../assets/icons/wcw-icon.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 50px;
        height: 50px;
    }
    .mgfee h2 {
        margin: 0px 25px 10px 25px;
        font-size: 25px;
        color: #fdfb54;
    }
    .mgfee p {
        margin: 0px 25px;
        font-size: 15px;
        color: #fdfb54;
    }
    #textFee {
        height: 130px;
        margin-top: 25px;
    }
    .mgfee table {
        width: 215px;
        margin: 0px 25px;
    }
    #firstCell {
        height: 38px !important;
        font-size: 24px;
    }
    .mgfee table td {
        font-size: 26px;
        color: #fff;
        text-align: right;
        height: 58px;
    }
    .tbContent table {
        margin-top: 180px;
    }
    .tbContent td {
        height: 56px;
    }
    /* || BUILDINGS */
    #buildings {
        width: 1200px;
        height: 424px;
    }
    .firstCard, #firstButton {
        margin-left: 85px;
    }
    .cards_buildings {
        width: 240px;
        height: 395px;
        margin-top: 60px;
    }
    .counter {
        z-index: -1;
        background-image: url("./assets/dov/R_F2_PRODUCER_SUPPLY.png");
    }
    .cards_button {
        width: 240px;
        margin: 20px 20px 20px 0;
        height: 117px;
    }
    .dovxZone {
        width: 240px;
        height: 115px;
        margin-top: 20px;
    }
    .dovxRedZone {
        width: 240px;
        height: 115px;
    }
    .dovxText {
        width: 120px;
        height: 57px;
        margin: 0px;
    }
    .dovxText h3 {
        font-size: 16px;
        margin: 10px 0 0 0;
    }
    .dovxText p {
        color: red;
        font-size: 18px;
        margin: 8px 0 0 0;
    }
    .dovxText h4 {
        font-size: 18px;
        margin: 8px 0 0 0; 
        color: green;
    }
    .selected:hover {
        background-size: 190px;
    }
    .cards_buildings img {
        width: 180px;
    }
    .imgcraft {
        width:158px  !important;
    }
    .characteristic {
        width: 930px;
        height: 237px;
        top: 555px;
        left: 30%;
    }
    .charContent p {
        font-size: 18px;
    }
    .charContent table {
        font-size: 20px;
        margin-top: 15px;
    }
    .charContent table tr {
        height: 40px;
    }
    .charContent table tr td:first-child {
        width: 300px;
    }
    .startCounter, .startRedCounter {
        width: 240px;
        height: 518px;
        margin-left:35px;
    }
    .endCounter {
        width: 240px;
        height: 518px;
        margin-left: 35px;
    }
    .floatText {
        bottom: 155px;
    }
    .textCounter {
        margin-top: 20px;
    }
    .textRedCounter {
        margin-top: 20px;
    }
    .craftBtn {
        background-size: cover;
        width: 109px;
        height: 33px;
        margin-top: 12px;
    }
    .textCounter h2, .textRedCounter h2 {
        font-size: 20px;
        font-weight: 500;
        color: #303030;
        line-height: 1;
    }
    .textCounter h3 {
        font-size: 20px;
        margin: 0px;
    }
    .textRedCounter h3 {
        font-size: 25px;
    }
    .textCounter p {
        font-size: 21px;
    }
    .textRedCounter p {
        font-size: 24px;
    }
    .disassemblyBtn {
        width: 121px;
        height: 37px;
        margin-top: 4px;
    }
    .craftBtn {
        width: 121px;
        height: 37px;
        margin-top: 11px;
    }
    .collect_button {
        width: 240px;
    }
    .collect_button img {
        width: 240px;
        height: 115px;
    }
    /* TOOLS */
    .tools-asset-name {
        padding: 0px;
    }
    .tools-asset-name h2 {
        font-size: 17px;
    }
    .tools-asset-rate {
        padding: 6px 0;
    }
    .tools-asset-rate h3 {
        font-size: 20px;
    }
}



@media (max-width: 1500px) {
    .topTitle span {
        font-size: 30px;
    }
    .leftZone {
        margin-left: 0px;
        width: 225px;
    }
    .btnZone {
        width: 100px;
    }
    .btnZone button {
        font-size: 12px;
        height: 18px;
    }
    .openZone {
        height: 95px;
    }
    .imgZone {
        width: 75px;
    }
    .imgZone img {
        width: 75px;
        height: 85px;
    }
    .costZone {
        width: 196px;
        height: 76px;
    }
    .costZone span {
        font-size: 15px;
        padding-top: 15px;
        line-height: 12px;
    }
    .winsZone {
        width: 196px;
        height: 31px;
    }
    .winsZone span {
        font-size: 24px;
        width: 55px;
    }
    .earningsZone {
        width: 196px;
        height: 28px;
    }
    .earningsZone span {
        font-size: 15px;
        padding-top: 8px;
    }
    .divFightInfo2 {
        margin-top: -2px;
    }
    .divZoneMin {
        margin: 0 40px 2px 20px;
    }
    span.orangeBlock {
        padding: 6px 8px;
        font-size: 18px;
    }
    .costZoneMin {
        width: 140px;
        height: 54px;
    }
    .costZoneMin span {
        padding-top: 20px;
        font-size: 11px;
        padding-right: 5px;
    }
    .winsZoneMin {
        width: 140px;
        height: 22px;
    }
    .winsZoneMin span {
        font-size: 17px;
        padding-top: 13px;
        width: 40px;
    }
    .earningsZoneMin {
        width: 140px;
        height: 20px;
    }
    .earningsZoneMin span {
        font-size: 11px;
        padding-top: 11px;
        margin-right: 5px;
    }
    .divFightButton, .divFightTitle {
        height: 80%;
    }
    .divFightTitle span {
        line-height: 125px;
    }
    .btnFight button {
        min-width: 120px;
    }
    /* CREATE DIVISION */
    .leftZone {
        margin-left: 0px;
        height: 760px;
    }
    .rightZone {
        margin-left: 20px;
        width: 900px;
        height: 760px;
    }
    .divSelector {
        width: 495px;
    }
    .divCards {
        width: 405px;
    }
    .divBtn button {
        width: 141px;
    }
    #board_division {
        width: 449px;
        height: 186px;
    }
    #board_division2 {
        width: 449px;
        height: 289px;
    }
    #one {
        top: 56px;
        font-size: 10px;
        left: -44px;
    }
    #cards_front_title {
        font-size: 10px;
        width: 35px;
    }
    #second {
        top: 30px;
        left: -48px;
    }
    #third {
        left: -48px;
        top: 139px;
    }
    #cards_hq_title {
        width: 34px;
    }
    .cards_hq {
        width: 81px;
        margin: 42px 22px 0 0;
    }
    .cards_hq img {
        width: 60px;
        height: 110px;
    }
    .cards_front {
        width: 80px;
        margin: 26px 0 0 0;
    }
    .blocCards {
        height: auto;
    }
    .divLeftDP {
        font-size: 14px;
    }
    .divAuth button, .divVDD button, .titleSelector {
        font-size: 14px;
    }
    #selectCard select {
        min-width: 85px;
        font-size: 14px;
    }
    /* END CREATE DIVISION */
    .token {
        width: 1065px;
        height: 65px;
    }
    .token div {
        font-size: 17px;
    }
    #dov-x p, #dov-f p, #dov-h p, #dov-s p, #dov-r p {
        margin: 35px 0 0 64px;
    }
    #dov-x {
        margin-left: 415px;
    }
    /* || MENU */
    .menu {
        min-width: 162px;
        background: url('./assets/dov/menu_bandeau_gauche.jpg') no-repeat;
        background-size: cover;
    }
    #avatar {
        top: 156px;
        width: 104px;
        height: 104px;
    }
    #account_name {
        font-size: 13px;
    }
    .fa {
        font-size: 13px !important
    }
    #account_block {
        top: 175px;
    }
    #menuTheater {
        background-size: contain;
        background-repeat: no-repeat;
        top: 165px;
        width: 170px;
        height: 121px;
    }
    #menuTheater span, #menuAssets span {
        height: 36px;
    }
    .midMenu {
        width: 82px;
        height: 65px;
    }
    #menuDivision, #menuMissions, #menuHOF {
        top: 160px;
    }
    .btn {
        width: 170px;
        height: 65px;
    }
    #menuWar, #menuAssets {
        top: 160px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 170px;
        height: 93px;
    }
    #menuTheater span, #menuAssets span, #menuWar span, #menuDivision span {
        height: 44px;
        line-height: 45px;
    }
    .midMenuMin, .midMenuWar {
        width: 82px;
        height: 30px;
    }
    #bandeau_haut {
        width: 1170px;
    }
    #table_division1 {
        width: 211px;
        height: 222px;
    }
    #table_division2, #table_division3 {
        width: 205px;
        height: 222px;
    }
    #stats_division {
        width: 245px;
        height: 353px;
    }
    #artillery_division {
        width: 369px;
        height: 355px;
    }
    /* || DIVISIONS */
    .divisions, .divisions:hover, .divisionsADD, .divisionsDOV {
        width: 1230px;
        height: 141px;
    }
    .divImage {
        width: 145px;
    }
    .divAvatar {
        top: 29px;
        width: 83px;
        height: 83px;
    }
    .divTitle {
        width: 415px;
        font-size: 35px;
    }
    .divColumn, .stats {
        width: 206px;
    }
    .statsLarge {
        width: 248px;
    }
    .stats td,.statsLarge td {
        font-size:13px
    }
    .stats th {
        font-size: 15px;
        padding-right: 15px;
    }
    /* || TABLEAU CREATE DIVISION */
    .stats_divisions th {
        height: 30px;
        font-size: 16px;
    }
    #table_division1 .stats_divisions {
        width: 200px !important;
    }
    .stats_divisions {
        width: 193px;
    }
    .stats_divisions td {
        font-size: 11px;
        line-height: 15px;
    }
    .stats_divisions td:first-child {
        width: 125px;
    }
    /* || SELECT A CARD */
    #select_card {
        height: 332px;
    }
    #select_card_title {
        height: 22px;
    }
    .sCards {
        width: 76px;
        height: 80px;
    }
    .sCards img {
        width: 25px;
    }
    .sCards p {
        font-size: 6px;
    }

    /* || CART DETAILS */
    #selectedCard {
        height: 80px;
    }
    #selectedCard img {
        width: 38px;
        height: 72px;
        margin: 7px 20px 0 20px;
    }
    #selectedCard span {
        font-size: 14px;
        line-height: 14px;
    }
    #selectedTable {
        width: 212px;
        margin: 5px 0 0 7px
    }
    #selectedTable table td {
        font-size: 10px;
    }
    #selectedTable table td:first-child {
        width: 165px;
    }
    #selectedTerrain, #selectedBonus {
        width: 65px;
    }
}
