@import "../../global"

.episodes-page
  background-color: #F3ECE2

  .episodes-introduction
    margin: 80px 0
    @include flex-props-center
    @include tablet
      padding: 0 20px

    @include phone
      flex-direction: column

      svg
        @include phone
          width: 300px

    .episodes-introduction-text-container
      width: 600px
      height: auto
      background-color: #fff
      border-radius: 15px
      position: relative
      align-self: flex-start
      @include flex-props-center

      @include phone
        width: 300px
        margin-bottom: 30px
        align-self: center

      &:after
        content: ""
        width: 0
        height: 0
        border-style: solid
        border-width: 0 50px 50px 0
        border-color: transparent #ffffff transparent transparent
        position: absolute
        bottom: -50px
        right: 50px

      .episodes-introduction-text
        color: #8B80F9
        font-weight: 700
        font-size: 25px
        padding: 15px 40px

        @include phone
          font-size: 20px

  .episodes
    max-width: 1280px
    margin: 0 auto
    position: relative

    @include desktop
      max-width: 900px

    @include tablet
      max-width: 751px

    .episode
      padding-bottom: 80px
      @include flex-props-space-between

      @include phone
        flex-direction: column

      .episode-text-wrapper
        max-width: 40%
        @include phone
          order: 1

        @include tablet
          max-width: 41%

          @include phone
            width: 320px
            max-width: initial

          @include smallest-phone
            width: 270px

        .episode-text-title
          color: rgba(255, 94, 57, 0.3)
          font-size: 60px
          font-weight: 700
          margin-bottom: 20px
          text-transform: uppercase
          @include phone
            font-size: 52px

        .episode-text
          font-size: 21px
          line-height: 26px
          @include tablet
            font-size: 18px

      .episode-image
        width: 40%
        @include phone
          order: 2
          width: 300px

  .episodes-dashed-line
    position: absolute
    height: 100%
    left: 50%
    width: auto

    @include phone
      display: none

  .episodes-dashed-line-mobile
    display: none
    position: absolute
    height: 100%
    left: 10px
    width: auto

    @include phone
      display: block
