.pagination
  background-color: white
  font-family: "MuseoSans", sans-serif
  border-radius: 0px
  padding: 10px 10px
  display: flex
  justify-content: center

  .pagination-item
    font-size: 20px
    font-weight: 500
    margin-right: 10px
    cursor: pointer

    &:last-child
      margin-right: 0

  .pagination-item-active
    color: #8B80F9