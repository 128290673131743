@import "../../global"

.imgavatar
  width: 110px
  position: relative
  top: -4px
  left: -3px
  
.header
  @include flex-props-space-between
  padding: 20px 75px
  position: sticky
  background-color: #fff
  z-index: 1
  @include phone
    padding: 20px 25px

  & div:nth-child(2)
    display: none
    @include tablet
    display: block

  .nav-burger
    display: none
    @include tablet
      display: block

  .header-helper-div
    width: 100px
    @include desktop
      display: none

  .nav-wrapper
    @include tablet
      display: none

  .header-log-in
    background-color: #F05D5E
    border: none
    border-radius: 10px
    text-transform: uppercase
    font-size: 20px
    color: white
    width: 100px
    height: 40px
    font-weight: 700
    cursor: pointer
    @include flex-props-center
    @include small-phone
      font-size: 18px

  .header-wallet
    @include flex-props-center
    position: relative
    cursor: pointer
    @include phone
      margin-right: 25px

    .header-wallet-icon
      width: 24px
      height: auto
      margin-right: 10px
      z-index: 1

    .header-wallet-text
      font-weight: 700
      z-index: 1



    .header-wallet-logout-wrapper
      position: absolute
      width: 150%
      height: 0
      opacity: 0
      top: -20px
      left: -20px
      background-color: #fff
      @include flex-props-column-center
      justify-content: flex-end
      padding-bottom: 15px
      z-index: 1
      border-radius: 15px
      transition: height .5s ease, opacity .5s ease

      .header-wallet-logout-cross
        position: absolute
        top: 10px
        right: 10px
        width: 10px
        height: 10px
        .header-wallet-logout-cross-line
          display: block
          position: absolute
          width: 2px
          height: 100%
          background-color: #000
          transform: rotate(45deg)

          &:last-child
            transform: rotate(-45deg)

      .header-wallet-logout
        color: #fff
        border: none
        text-transform: uppercase
        background-color: #8B80F9
        padding: 5px 10px
        font-size: 16px
        border-radius: 7px
        font-weight: 700
        cursor: pointer

    .header-wallet-logout-wrapper-active
      height: 100px
      opacity: 1


// exported without nesting for it to work inside burger menu
.header-link
  font-weight: 900
  font-size: 20px
  margin-right: 40px
  color: #000
  padding-bottom: 4px
  text-transform: uppercase

  @include tablet
    font-size: 24px
    margin-bottom: 25px
    margin-right: 0

  &:last-child
    margin-right: 0
    margin-bottom: 0

.header-link-active
  color: #8B80F9
  border-bottom: 1px solid #8B80F9


  

  
.headerx-link-active
  color: #8B80F9
  border-bottom: 1px solid #8B80F9