@import "../../global"

.staking-page
  background-color: blue
  padding: 50px 50px
  width:100%
  
  .staking-info-wrapper
    margin-bottom: 80px
    @include flex-props-center
    @include tablet
      @include flex-props-column-center

    .staking-info
      background-color: #fff
      border-radius: 15px
      width: 450px
      height: 255px
      @include flex-props-column-center
      @include small-phone
        width: 300px

      &:first-child
        margin-right: 15px
        padding-left: 50px
        align-items: flex-start
        @include tablet
          margin-right: 0
          margin-bottom: 20px

      .staking-info-item
        @include flex-props-center
        margin-bottom: 35px

        &:last-child
          margin-bottom: 0

        .staking-info-item-wallet-icon
          width: 50px
          height: auto
          margin-right: 20px

        .staking-info-item-image
          width: 20px
          height: 20px
          margin-right: 20px

        .staking-info-item-text
          font-weight: 700
          font-size: 26px
          color: #000
          @include small-phone
            text-align: right

        .staking-info-item-title
          font-size: 20px
          color: #8B80F9
          font-weight: 500
          text-transform: uppercase

      .staking-info-item-apart
        justify-content: space-between
        width: 100%
        padding: 0 20px

      .staking-info-claim
        width: 150px
        height: 50px
        background-color: #f05d5e
        color: #fff
        border-radius: 10px
        text-transform: uppercase
        border: none
        font-size: 26px
        font-weight: 900
        font-family: "MuseoSans", sans-serif
        cursor: pointer

  .staking-assets-wrapper
    max-width: 1580px
    margin: 0 auto
    @include desktop
      padding: 0 20px

    .staking-assets-filters-wrapper
      margin-bottom: 40px
      @include flex-props-space-between
      align-items: flex-start
      @include small-phone
        @include flex-props-column-center

      .staking-assets-filters-buttons
        @include flex-props-center
        @include phone
          @include flex-props-column-center

        .staking-assets-filters-button
          font-size: 21px
          color: white
          font-weight: 900
          border: none
          border-radius: 8px
          width: 200px
          padding: 10px 20px
          overflow-wrap: normal
          cursor: pointer
          font-family: "MuseoSans", sans-serif
          text-transform: uppercase
          @include flex-props-center
          @include phone
            font-size: 18px
            width: 160px

        .staking-assets-filters-button-stake
          background-color: #F05D5E
          margin-right: 20px
          @include phone
            margin-right: 0
            margin-bottom: 20px

        .staking-assets-filters-button-unstake
          background-color: #8B80F9

      .staking-assets-filters
        width: 100%
        display: flex
        justify-content: flex-end
        align-items: center
        @include small-phone
          width: auto
          margin-top: 20px
          margin-left: 30px

        .staking-assets-filter
          border: none
          background-color: transparent
          font-size: 20px
          font-weight: 700
          color: #000
          text-transform: uppercase
          margin-right: 15px
          font-family: "MuseoSans", sans-serif
          cursor: pointer
          @include phone
            font-size: 18px

        .staking-assets-filter-active
          color: #8B80F9
          padding-bottom: 3px
          border-bottom: 2px solid #8B80F9

        .staking-assets-vertical-line
          width: 3px
          height: 17px
          background-color: #000
          margin-right: 15px

    .staking-assets-container
      @include flex-props-column-center
      .staking-assets
        margin-bottom: 20px
        transition: height .5s ease
        @include flex-props-center
        flex-wrap: wrap
        & > div //remove later
          transition: height .5s ease
          @include flex-props-center
          flex-wrap: wrap

        .staking-asset
          margin-right: 50px
          margin-bottom: 50px
          @include flex-props-column-center

          &:nth-child(4n)
            margin-right: 0

          @include desktop
            &:nth-child(4n)
              margin-right: 50px

          @include phone
            &:nth-child(2n)
              margin-right: 0
          @include small-phone
            margin-right: 0

          .staking-asset-name-wrapper
            min-width: 150px
            height: 50px
            text-align: left
            background-color: #fff
            border-radius: 10px 10px 0 0
            @include flex-props-center

            .staking-asset-name
              font-size: 20px
              font-weight: 700
              text-align: left
              color: #8B80F9
              text-transform: uppercase

          .staking-asset-img
            width: 190px
            height: 100px
            margin-bottom: 20px

          .staking-asset-rate
            font-size: 30px
            font-weight: 700
            color: #000
            margin-bottom: 20px

          .staking-asset-button
            font-size: 15px
            font-weight: 700
            padding: 15px 8px
            background-color: #8B80F9
            color: #fff
            border: none
            font-family: "MuseoSans", sans-serif
            border-radius: 10px
            text-transform: uppercase
            cursor: pointer

        .staking-no-assets-title
          font-size: 24px
          margin: 40px 0px

.optionSelect
    font-size: 14px

.wallet-warning
  width: 100%
  height: 80vh
  @include flex-props-center

  .warning-message
    font-size: 24px
    @include small-phone
      padding: 0 20px
      text-align: center
      line-height: 30px

