//The use of !important is explained by the library overwriting any styles of any form.

/* Position and sizing of burger button */
.bm-burger-button
  position: relative
  width: 36px
  height: 30px
  left: 0
  top: 0

/* Color/shape of burger icon bars */
.bm-burger-bars
  background: #F05D5E

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover
  background: #F05D5E

/* Position and sizing of clickable cross button */
.bm-cross-button
  height: 35px !important
  width: 35px !important

  & > button
    left: -15px !important
    top: 20px !important

  & > span
    top: 35px !important
    right: 50px !important

/* Color/shape of close button cross */
.bm-cross
  background: #F05D5E
  width: 35px !important
  height: 4px !important


//Sidebar wrapper styles
//Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases

.bm-menu-wrap
  position: fixed
  height: 100%
  left: 0
  top: 0

/* General sidebar styles */
.bm-menu
  background: #F3ECE2
  padding: 2.5em 1.5em 0
  font-size: 1.15em

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape
  fill: #373a47

/* Wrapper for item list */
.bm-item-list
  color: #b8b7ad

  &:focus
    outline: none !important

/* Individual item */
.bm-item
  padding: 10px 50px

  &:focus
    outline: none !important

/* Styling of overlay */
.bm-overlay
  background: rgba(0, 0, 0, 0.6) !important
  top: 0
  left: 0

// Custom styles not connected to the lib

.nav-burger-content
  height: 100%
  flex-direction: column
  justify-content: center
  align-items: flex-start

.body-burger-active
  overflow: hidden
