@import "../../global"

.token
    @include flex-props-center
    background: url('bandeau_token.jpg')
    background-repeat: no-repeat
    background-position: top right
    background-size: contain
    display: inline-flex
    width:100%
    height:86px
    justify-items: flex-end

.head 
  width: 100%
  display: inline-grid
  align-items: flex-end
  justify-content: flex-end
