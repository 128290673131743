@font-face
  font-family: "MuseoSans"
  font-style: normal
  font-weight: 900
  src: local('MuseoSans'), url("./assets/fonts/MuseoSans/MuseoSansCyrl-900.ttf") format("truetype")

@font-face
  font-family: "MuseoSans"
  font-style: normal
  font-weight: 700
  src: local('MuseoSans'), url("./assets/fonts/MuseoSans/MuseoSansCyrl-700.ttf") format("truetype")

@font-face
  font-family: "MuseoSans"
  font-style: normal
  font-weight: 500
  src: local('MuseoSans'), url("./assets/fonts/MuseoSans/MuseoSansCyrl-500.ttf") format("truetype")

@font-face
  font-family: "MuseoSans"
  font-style: normal
  font-weight: 300
  src: local('MuseoSans'), url("./assets/fonts/MuseoSans/MuseoSansCyrl-300.ttf") format("truetype")

@font-face
  font-family: "RF Krabuler"
  font-style: normal
  font-weight: 300
  src: local('RF Krabuler'), url("./assets/fonts/RFKrabuler/RFKrabuler-300.ttf") format("truetype")
